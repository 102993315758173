import { AxiosRequestConfig } from 'axios'

import { FindAllResponseModel } from 'api/nest/NestCollection'
import { NestController } from 'api/nest/NestController'
import { OrderModel } from 'api/nest/orders/types'
import { NestPaginationQuery, NestPaginationQueryWithSearch } from 'api/nest/types'

import {
  ChangeBrokerPasswordRequestModel,
  UpdateBrokerRequestModel,
  UpdateBrokerResponseModel,
} from './types'

export class BrokerController extends NestController {
  constructor() {
    super({ controller: 'brokers' })
  }

  public updateBrokerByUuid = (uuid: string, data: UpdateBrokerRequestModel) => {
    return this.patch<UpdateBrokerResponseModel>(`/${uuid}`, data)
  }

  public changeBrokerPassword = (uuid: string, data: ChangeBrokerPasswordRequestModel) => {
    return this.patch<UpdateBrokerResponseModel>(`/${uuid}/password`, data)
  }

  public downloadReferralCsv = async () => {
    const response = await this.request.get<string>('/me/referrals/csv')
    return this.getFileInfoForSave(response)
  }

  public downloadContractsCsv = async () => {
    const response = await this.request.get<string>('/me/contracts/csv')
    return this.getFileInfoForSave(response)
  }

  public getMyOrders = (params?: NestPaginationQueryWithSearch, config?: AxiosRequestConfig) => {
    return this.get<FindAllResponseModel<OrderModel>>(`/me/orders`, params, config)
  }

  public updateAuthBrokerInfo = (uuid: string, data: UpdateBrokerRequestModel) => {
    return this.patch<UpdateBrokerResponseModel>(`${uuid}/me`, data)
  }

  public validateCodeFromEmail = (uuid: string, token: string) => {
    return this.post<UpdateBrokerResponseModel>(`${uuid}/validate`, { token })
  }
}
