import { DeepPartial } from 'react-hook-form'
import { ValidationError } from 'yup'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { OrderTerms } from 'api/nest/orders/types'
import { PageStep } from 'components/stepper'
import { FlowUserRole } from 'enum/flow-user-role'
import { PurchaserTypeEnum } from 'enum/purchaser-type'
import { isDebugMode } from 'utils/helpers'
import { routes } from 'utils/routes'
import { useFlowStorage } from 'utils/use-flow-storage'

import {
  CONTRACT_STEP_SCHEMA,
  COVERAGE_STEP_SCHEMA,
  MARKET_STEP_SCHEMA,
  PAYMENT_SUCCESS_SCHEMA,
  PERSONAL_INFO_STEP_SCHEMA,
  PURCHASER_INFO_STEP_SCHEMA,
  PROPERTY_STEP_SCHEMA,
  REVIEW_STEP_SCHEMA,
  REVIEW_STEP_SCHEMA_ATTESTATION,
} from './use-flow-storage.schemas'
import {
  ConfigurationFlowStorage,
  MarketConfiguration,
  PropertyConfiguration,
} from './use-flow-storage.types'

//TODO: May be it's to much. May be rewrite it to schema.isValidSync
const isValidBySchema = <T extends object | undefined>(
  data: T,
  schema: any, // TODO: replace with AnyObjectSchema or ObjectSchema<any> — see https://github.com/jquense/yup/issues/1849
  stepName: string,
): boolean => {
  const isShowDebugInfo = isDebugMode()

  try {
    schema.validateSync(data, { stripUnknown: true, abortEarly: false })
    // eslint-disable-next-line no-console
    isShowDebugInfo && console.log(`%c${stepName} step is finished`, 'color: lightseagreen')
    return true
  } catch (error: unknown) {
    if (isShowDebugInfo && ValidationError.isError(error)) {
      /* eslint-disable no-console */
      console.groupCollapsed(`%c${stepName} step is not finished`, 'color: red')
      error.inner.forEach((e) => {
        console.group(`%c${e.path}`, 'color: yellow')
        console.log(`Error:`, e.message)
        console.log(`Value:`, e.value)
        console.groupEnd()
      })
      console.groupEnd()
      /* eslint-enable no-console */
    }
    return false
  }
}

// TODO: replace `any` arg typings following with AnyObjectSchema or ObjectSchema<any> — see https://github.com/jquense/yup/issues/1849

export const isFinishStepMarket = (
  stepData?: MarketConfiguration,
  schema: any = MARKET_STEP_SCHEMA,
) => {
  return isValidBySchema(stepData, schema, 'Market')
}

export const isFinishStepCoverage = (
  stepData?: DeepPartial<ConfigurationFlowStorage['coverage']>,
  schema: any = COVERAGE_STEP_SCHEMA,
) => {
  return isValidBySchema(stepData, schema, 'Coverage')
}

export const isFinishStepPersonalInfo = (
  stepData?: DeepPartial<ConfigurationFlowStorage['personalInfo']>,
  schema: any = PERSONAL_INFO_STEP_SCHEMA,
) => {
  return isValidBySchema(stepData, schema, 'Personal Info')
}

export const isFinishStepPurchaserInfo = (
  stepData: DeepPartial<ConfigurationFlowStorage['purchaserInfo']> | undefined,
) => {
  return isValidBySchema(stepData, PURCHASER_INFO_STEP_SCHEMA, 'Purchaser Info')
}

export const isFinishStepReview = (
  stepData?: DeepPartial<ConfigurationFlowStorage['reviewInformation']>,
  schema: any = REVIEW_STEP_SCHEMA,
) => {
  return isValidBySchema(stepData, schema, 'Review Information')
}

export const isFinishStepPayment = (
  stepData?: DeepPartial<ConfigurationFlowStorage['payment']>,
  schema: any = PAYMENT_SUCCESS_SCHEMA,
) => {
  return isValidBySchema(stepData, PAYMENT_SUCCESS_SCHEMA, 'Payment')
}

export const isFinishStepProperty = (
  stepData?: PropertyConfiguration,
  schema: any = PROPERTY_STEP_SCHEMA,
) => {
  return isValidBySchema(stepData, schema, 'Property')
}

export const isFinishStepContract = (
  stepData?: DeepPartial<ConfigurationFlowStorage['contract']>,
  schema: any = CONTRACT_STEP_SCHEMA,
) => {
  return isValidBySchema(stepData, schema, 'Contract')
}

export const parseYearFromTerm = (period: OrderTerms): number => {
  return period ? Number(period.replace(/\D/g, '')) : 0
}

// https://twitter.com/diegohaz/status/1667197904006569991
const nonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null
}

export const getFlowSteps = (flowStorage: ConfigurationFlowStorage): PageStep[] => {
  const { purchaserType, role } = flowStorage

  return [
    role === undefined ||
    role === FlowUserRole.GiftPurchaser ||
    role === FlowUserRole.Partner ||
    role === FlowUserRole.Solo
      ? {
          title: 'Market',
          link:
            role === FlowUserRole.Partner
              ? routes.partnerPortalNewOrderStepMarket
              : routes.stepMarket,
          finished: isFinishStepMarket(flowStorage.market),
        }
      : null,
    role === undefined ||
    role === FlowUserRole.ContractHolder ||
    role === FlowUserRole.GiftPurchaser ||
    role === FlowUserRole.Partner ||
    role === FlowUserRole.Solo
      ? {
          title: 'Property',
          link:
            role === FlowUserRole.Partner
              ? routes.partnerPortalNewOrderStepProperty
              : routes.stepProperty,
          finished: isFinishStepProperty(flowStorage.property),
        }
      : null,
    role === undefined ||
    (role === FlowUserRole.ContractHolder && purchaserType === PurchaserTypeEnum.contractHolder) ||
    role === FlowUserRole.GiftPurchaser ||
    role === FlowUserRole.Partner ||
    role === FlowUserRole.Solo
      ? {
          title: 'Selections',
          link:
            role === FlowUserRole.Partner
              ? routes.partnerPortalNewOrderStepCoverage
              : routes.stepCoverage,
          finished: isFinishStepCoverage(flowStorage.coverage),
        }
      : null,
    role === undefined ||
    role === FlowUserRole.ContractHolder ||
    role === FlowUserRole.GiftPurchaser ||
    role === FlowUserRole.Purchaser ||
    role === FlowUserRole.Solo
      ? {
          title: 'Personal Info',
          link: routes.stepPersonalInfo,
          finished: isFinishStepPersonalInfo(
            flowStorage.personalInfo,
            flowStorage.role === FlowUserRole.GiftPurchaser ||
              flowStorage.role === FlowUserRole.Purchaser
              ? PURCHASER_INFO_STEP_SCHEMA
              : PERSONAL_INFO_STEP_SCHEMA,
          ),
        }
      : null,
    purchaserType === PurchaserTypeEnum.other && role === FlowUserRole.Partner
      ? {
          title: 'Purchaser Info',
          link: routes.partnerPortalNewOrderStepPurchaserInfo,
          finished: isFinishStepPurchaserInfo(flowStorage.purchaser),
        }
      : null,
    role === undefined ||
    role === FlowUserRole.ContractHolder ||
    role === FlowUserRole.GiftPurchaser ||
    role === FlowUserRole.Partner ||
    role === FlowUserRole.Solo
      ? {
          title: 'Review',
          link:
            role === FlowUserRole.Partner
              ? routes.partnerPortalNewOrderStepReview
              : routes.stepReview,
          finished: isFinishStepReview(
            flowStorage.reviewInformation,
            flowStorage.role === FlowUserRole.GiftPurchaser ||
              flowStorage.role === FlowUserRole.Partner
              ? REVIEW_STEP_SCHEMA
              : REVIEW_STEP_SCHEMA_ATTESTATION,
          ),
        }
      : null,
    role === undefined || role === FlowUserRole.ContractHolder || role === FlowUserRole.Solo
      ? {
          title: 'Contract',
          link: routes.stepContract,
          finished: isFinishStepContract(flowStorage.contract),
        }
      : null,
    role === undefined ||
    role === FlowUserRole.GiftPurchaser ||
    (role === FlowUserRole.ContractHolder && purchaserType === PurchaserTypeEnum.contractHolder) ||
    (role === FlowUserRole.Partner && purchaserType === PurchaserTypeEnum.broker) ||
    role === FlowUserRole.Purchaser ||
    role === FlowUserRole.Solo
      ? {
          title: 'Payment',
          link:
            role === FlowUserRole.Partner
              ? routes.partnerPortalNewOrderStepPayment
              : routes.stepPayment,
          finished: isFinishStepPayment(flowStorage.payment),
        }
      : null,
  ].filter(nonNullable)
}

export const getNextFlowStep = (flowStorage: ConfigurationFlowStorage): PageStep | undefined => {
  return getFlowSteps(flowStorage).find((step) => step.finished !== true && step.disabled !== true)
}

// after review step finished all previous pages shall be unaccessable.
// This hook prevents manual navigating to previous pages by modifying url.
export const useDisabledPagesBoundary = () => {
  const [flowStorageData] = useFlowStorage()
  const steps = getFlowSteps(flowStorageData)

  const { asPath, push } = useRouter()

  const websiteReviewStepUrl = '/configuration-flow/review'
  const partnerPortalReviewStepUrl = '/partner-portal/orders/new/review'

  const isReviewStepFinished = Boolean(flowStorageData.reviewInformation?.reviewed)
  const isMarketStepFinished = Boolean(flowStorageData.market?.uuid)
  const currentStep = steps.find((step) => asPath.startsWith(step.link))

  useEffect(() => {
    const noReviewStep = () => {
      const reviewStepIndex = steps.findIndex(
        (step) =>
          step.link.startsWith(websiteReviewStepUrl) ||
          step.link.startsWith(partnerPortalReviewStepUrl),
      )

      return reviewStepIndex === -1
    }

    if (noReviewStep()) {
      return
    }

    const isCurrentStepBeforeReview = () => {
      const currentStepIndex = steps.findIndex((step) => asPath.startsWith(step.link))
      const reviewStepIndex = steps.findIndex(
        (step) =>
          step.link.startsWith(websiteReviewStepUrl) ||
          step.link.startsWith(partnerPortalReviewStepUrl),
      )

      return reviewStepIndex > currentStepIndex
    }

    if (
      (isReviewStepFinished && isCurrentStepBeforeReview()) || // to disable all steps before Review after Review is finished
      (isMarketStepFinished && currentStep?.title === 'Market') || // to disable Market step right after it was finished
      (currentStep?.finished && !isCurrentStepBeforeReview()) // to disable only those steps after Review which already has been finished
    ) {
      const nextStep = steps.filter((el) => !el.finished)[0]
      if (nextStep) {
        push(nextStep.link)
      }
    }
  }, [
    isReviewStepFinished,
    isMarketStepFinished,
    steps,
    currentStep?.finished,
    currentStep?.title,
    asPath,
    push,
  ])
}
