import { ReactNode } from 'react'
import { useToggle } from 'react-use'

import { ButtonProps } from 'components/button'
import { LinkButton } from 'components/links'
import { FlowUserRole } from 'enum/flow-user-role'
import { useFlowStorage } from 'utils/use-flow-storage'
import { ConfigurationFlowStorage } from 'utils/use-flow-storage.types'
import { AddOrderDialog } from 'sections/pp-dashboard-page'
import { useControlRouterDialog } from 'components/dialog'
import { DIALOG_NAME } from 'const/dialogs'

export const BuyNowButton = (
  props: {
    children?: ReactNode
    className?: string
    flowStorageData?: ConfigurationFlowStorage
    toClearStorage?: boolean
    toIgnoreSessionRole?: boolean
    // "toIgnoreSessionRole" created to cover edge cases when user jumps off specific buying process or PP buying process, role is
    // stored in session storage, e.g. - 'partner'. This component checks (outdated role) and show wrong modal (as for partner).
    // TODO: add global url listener to clear role if not in buying process.
    onClick?: () => void
  } & ButtonProps,
) => {
  const { open } = useControlRouterDialog()

  const [isNewOrderDialogOpen, setIsNewOrderDialogOpen] = useToggle(false)
  const [flowStorageData, setFlowStorageData] = useFlowStorage()

  const handleClick = () => {
    const role = props.toIgnoreSessionRole ? null : flowStorageData.role
    if (
      role === FlowUserRole.Partner ||
      role === FlowUserRole.Purchaser ||
      role === FlowUserRole.ContractHolder
    ) {
      setIsNewOrderDialogOpen(true)
    } else {
      let newStorageData: ConfigurationFlowStorage = { ...flowStorageData }
      if (props.flowStorageData) {
        newStorageData = { ...props.flowStorageData }
      }
      newStorageData.toClearStorage = Boolean(props.toClearStorage)

      setFlowStorageData(newStorageData)
      open(DIALOG_NAME.buyNowDialog)
    }

    if (props.onClick) props.onClick()
  }

  return (
    <>
      <LinkButton
        className={props.className}
        href={props.disabled ? null : undefined}
        theme={props.theme || 'primary'}
        onClick={handleClick}
      >
        {props.children || 'Buy Now'}
      </LinkButton>
      <AddOrderDialog
        open={isNewOrderDialogOpen}
        setDialogOpen={setIsNewOrderDialogOpen}
        onClose={() => setIsNewOrderDialogOpen(false)}
      />
    </>
  )
}
